import styled from "styled-components";

type HeadingElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const headingSizeMap: { [key in HeadingElement]: string } = {
  h1: "2.125rem",
  h2: "1.875rem",
  h3: "1.5rem",
  h4: "1.25rem",
  h5: "1.125rem",
  h6: "1rem",
};

interface Props extends React.ComponentProps<"h1"> {
  element?: HeadingElement;
}

const Heading = ({ element = "h1", children, className }: Props) => {
  return (
    <Wrapper as={element} className={className}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.h1<{
  as: HeadingElement;
}>`
  font-size: ${(p) => headingSizeMap[p.as as HeadingElement]};
`;

Heading.Root = Wrapper;

export { Heading };
